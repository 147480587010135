import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';
import NavigationMenu from './Menus/NavigationMenu.jsx';
// import SearchModal from '../SearchModal/SearchModal.jsx';
import GiveMenu from './Menus/Give.jsx';
import MyAccountMenu from './Menus/MyAccount.jsx';
import MySiteMenu from './Menus/MySite.jsx';
import styles from './SharedHeader.styles.css';
import { getCookieByKey, getSubdomain } from '../../lib/utils.js';
import * as iconData from './icons.json';
import { fetchHeaderData } from '../../lib/shared-header-utils.js';
import AuthProvider from './authProvider.js';

const DEFAULT_AVATAR_IMAGE = 'https://crossroads-media.imgix.net/images/avatar.svg';

const SharedHeader = () => {
  const [userState, setUserState] = useState({
    isLoading: true,
    isAuthenticated: false,
    user: null
  });
  const [serviceTimes, setServiceTimes] = useState([]);
  const [happenings, setHappenings] = useState([]);
  const [navState, setNavState] = useState({
    openNavName: '',
    activeMenu: '',
    nestedContent: null,
    activeNavItem: ''
  });
  // const [isSearchModalOpen, setSearchModalOpen] = useState(false);
  const [headerData, setHeaderData] = useState(null);

  const dropdownRefs = useRef({
    'my-site-nav': null,
    'give-nav': null,
    'profile-nav': null
  });

  const rootURL = `https://${getSubdomain(process.env.CRDS_ENV)}.crossroads.net`;

  useEffect(() => {
    const fetchHeaderDataAsync = async () => {
      try {
        const formattedEnv = process.env.CRDS_ENV === 'production' ? 'prod' : process.env.CRDS_ENV;
        const data = await fetchHeaderData(
          `https://crds-data.crossroads.net/shared-header/${formattedEnv}.json`
        );
        setHeaderData(data);
      } catch (error) {
        console.error('Error fetching header data:', error);
      }
    };

    fetchHeaderDataAsync();
  }, []);

  useEffect(() => {
    const initializeUser = async () => {
      try {
        await AuthProvider.initialize();
        setUserState({
          isLoading: false,
          isAuthenticated: AuthProvider.getIsAuthenticated(),
          user: AuthProvider.getUser()
        });
      } catch (error) {
        console.error('Error initializing authentication:', error);
        setUserState({ isLoading: false, isAuthenticated: false, user: null });
      }
    };

    initializeUser();
  }, []);

  useEffect(() => {
    if (userState.user) {
      fetchServiceTimes(userState.user.PrimaryCampusName);
      fetchHappenings(userState.user.PrimaryCampusName);
    }
  }, [userState.user]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!navState.openNavName) return;

      const openMenuRef = dropdownRefs.current[navState.openNavName];

      if (openMenuRef) {
        const path = event.composedPath && event.composedPath();
        if (path && !path.includes(openMenuRef)) {
          toggleMenu(navState.openNavName);
        }
      }
    };

    if (navState.openNavName) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      if (navState.openNavName) {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
    };
  }, [navState.openNavName]);

  const toggleMenu = (menuName) => {
    setNavState((prevMenu) => {
      const newOpenNavName = prevMenu.openNavName === menuName ? '' : menuName;
      document.body.style.overflow = newOpenNavName ? 'hidden' : 'scroll';

      return {
        openNavName: newOpenNavName,
        activeMenu: '',
        nestedContent: null,
        activeNavItem: ''
      };
    });
  };

  const handleMenuClick = (item) => {
    setNavState((prevState) => ({
      ...prevState,
      activeMenu: 'nested',
      nestedContent: item,
      activeNavItem: item.title
    }));
  };

  const handleBackClick = () => {
    setNavState((prevState) => ({
      ...prevState,
      activeMenu: '',
      nestedContent: null,
      activeNavItem: ''
    }));
  };

  const fetchServiceTimes = async (campusName) => {
    if (!campusName) return;

    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_CRDS_SITE_DOMAIN}api/headerServiceTimes?campusName=${encodeURIComponent(campusName)}`
      );
      const serviceTimes = await response.json();

      setServiceTimes(serviceTimes || []);
    } catch (error) {
      console.error('Error fetching service times:', error);
    }
  };

  const fetchHappenings = async (campusName) => {
    if (!campusName) return;

    try {
      const queryCampusName = campusName === 'Crossroads Church Online' ? 'Anywhere' : campusName;

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_CRDS_SITE_DOMAIN}api/headerHappenings?campusName=${encodeURIComponent(queryCampusName)}`
      );
      const happenings = await response.json();

      setHappenings(happenings || []);
    } catch (error) {
      console.error('Error fetching happenings:', error);
    }
  };

  const getBackgroundClass = (title) => {
    const titleToClassMap = {
      'Come Visit': 'section--come-visit',
      'Watch, Listen, Read': 'section--watch-listen-read',
      'Get Connected': 'section--find-community',
      'Get Support': 'section--get-support',
      'GO | Change The World': 'section--go-change-the-world'
    };

    return titleToClassMap[title] || 'section--default';
  };

  const renderGlobalActions = () => (
    <div class="global-actions">
      <div
        class={`menu-container ${navState.openNavName === 'main-nav' ? 'nav-is-showing' : ''}`}
        data-automation-id="sh-menu"
        onClick={() => toggleMenu('main-nav')}
        data-label="menu"
      >
        <div
          class={navState.openNavName === 'main-nav' ? iconData.close.class : iconData.main.class}
          dangerouslySetInnerHTML={{
            __html:
              navState.openNavName === 'main-nav'
                ? iconData.close.innerHTML
                : iconData.main.innerHTML
          }}
        />
      </div>
      <a href={`${rootURL}/search`}>
        <div
          class="search-container"
          data-automation-id="sh-search"
          aria-label="search"
          data-label="search"
        >
          <div
            class={iconData.search.class}
            dangerouslySetInnerHTML={{ __html: iconData.search.innerHTML }}
          />
        </div>
      </a>
      <a href={`${rootURL}/watch`}>
        <div
          class="watch-container"
          data-automation-id="sh-watch"
          aria-label="watch"
          data-label="watch"
        >
          <div
            class={iconData.watch.class}
            dangerouslySetInnerHTML={{ __html: iconData.watch.innerHTML }}
          />
        </div>
      </a>
      {/* <div
        onClick={(e) => {
          e.preventDefault();
          setSearchModalOpen(true);
        }}
        class="search-container"
        data-automation-id="sh-search"
        data-label="search"
      >
        <div
          class={iconData.search.class}
          dangerouslySetInnerHTML={{ __html: iconData.search.innerHTML }}
        />
      </div> */}
    </div>
  );

  const renderLogo = () => {
    const isAuthenticatedClass = userState.isAuthenticated ? 'authenticated' : '';

    return (
      <a
        href={rootURL}
        class={`logo ${isAuthenticatedClass}`}
        data-automation-id="sh-logo"
        data-label="home"
        dangerouslySetInnerHTML={{ __html: iconData.logo.innerHTML }}
      />
    );
  };

  const renderUserActions = () => {
    const { isAuthenticated, user } = userState;

    const profileIconClass =
      navState.openNavName === 'profile-nav' ? iconData.close.class : iconData.profile.class;
    const profileIconHTML =
      navState.openNavName === 'profile-nav'
        ? iconData.close.innerHTML
        : isAuthenticated
          ? `<div class="account-authenticated" style="background-image: url('${user?.picture || 'https://crossroads-media.imgix.net/images/avatar.svg'}');"/>`
          : iconData.profile.innerHTML;

    return (
      <div class="user-actions">
        {user?.PrimaryCampusName && (
          <div
            ref={(el) => (dropdownRefs.current['my-site-nav'] = el)}
            class={`my-site-container ${navState.openNavName === 'my-site-nav' ? 'nav-is-showing' : ''}`}
            data-automation-id="sh-my-site"
            data-label={user.PrimaryCampusName}
            onClick={() => toggleMenu('my-site-nav')}
          >
            <div
              class={
                navState.openNavName === 'my-site-nav' ? iconData.close.class : iconData.site.class
              }
              dangerouslySetInnerHTML={{
                __html:
                  navState.openNavName === 'my-site-nav'
                    ? iconData.close.innerHTML
                    : iconData.site.innerHTML
              }}
            />
            {navState.openNavName === 'my-site-nav' && (
              <MySiteMenu
                isNavOpen={navState.openNavName === 'my-site-nav'}
                user={user}
                serviceTimes={serviceTimes}
                happenings={happenings}
                handleClose={() => toggleMenu('my-site-nav')}
              />
            )}
          </div>
        )}
        <div
          ref={(el) => (dropdownRefs.current['give-nav'] = el)}
          class={`give-container ${navState.openNavName === 'give-nav' ? 'nav-is-showing' : ''}`}
          data-label="give"
          data-automation-id="sh-give"
          onClick={() => toggleMenu('give-nav')}
        >
          <div
            class={navState.openNavName === 'give-nav' ? iconData.close.class : iconData.give.class}
            dangerouslySetInnerHTML={{
              __html:
                navState.openNavName === 'give-nav'
                  ? iconData.close.innerHTML
                  : iconData.give.innerHTML
            }}
          />
          {navState.openNavName === 'give-nav' && (
            <GiveMenu
              isNavOpen={navState.openNavName === 'give-nav'}
              data={headerData.give}
              handleClose={() => toggleMenu('give-nav')}
            />
          )}
        </div>
        {isAuthenticated ? (
          <div
            ref={(el) => (dropdownRefs.current['profile-nav'] = el)}
            className={`profile-container ${navState.openNavName === 'profile-nav' ? 'nav-is-showing' : ''}`}
            data-label="My Account"
            data-automation-id="sh-profile"
            onClick={() => toggleMenu('profile-nav')}
          >
            <div
              className={profileIconClass}
              dangerouslySetInnerHTML={{ __html: profileIconHTML }}
            />
            {navState.openNavName === 'profile-nav' && (
              <MyAccountMenu
                data={headerData.profile}
                isNavOpen={navState.openNavName === 'profile-nav'}
                user={user}
                handleClose={() => toggleMenu('profile-nav')}
              />
            )}
          </div>
        ) : (
          <a
            href="#"
            className="profile-container"
            data-label="Sign In"
            data-automation-id="sh-sign-in"
            onClick={(e) => {
              e.preventDefault();
              AuthProvider.login();
            }}
          >
            <div
              className={profileIconClass}
              dangerouslySetInnerHTML={{ __html: profileIconHTML }}
            />
          </a>
        )}
      </div>
    );
  };

  if (userState.isLoading) {
    return null;
  }

  return (
    <ShadowWrapper styles={styles}>
      <header className={navState.openNavName ? 'nav-is-showing' : ''}>
        <div class="global-nav-items">
          {renderGlobalActions()}
          {renderLogo()}
          {renderUserActions()}
        </div>
      </header>
      <NavigationMenu
        navState={navState}
        headerData={headerData}
        handleMenuClick={handleMenuClick}
        handleBackClick={handleBackClick}
        getBackgroundClass={getBackgroundClass}
      />
      {/* <SearchModal isOpen={isSearchModalOpen} onClose={() => setSearchModalOpen(false)} /> */}
    </ShadowWrapper>
  );
};

export default SharedHeader;
